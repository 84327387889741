<template>
	<b-dropdown variant="link" class="my-0" no-caret right>
		<template #button-content>
			<div class="sub-task-nav-update" :id="`update-action-${index}`">
				<i class="fa fa-ellipsis-v" aria-hidden="true"></i>
				<b-tooltip :target="`update-action-${index}`" placement="topleft">
					{{ FormMSG(3, 'Actions') }}
				</b-tooltip>
			</div>
		</template>
		<b-dropdown-item action @click="updateTitle(item)">
			<div class="sub-task-dropdown-item-action">
				<div class="icon">
					<i class="fa fa-bars update-title" aria-hidden="true"></i>
				</div>
				<div class="sub-task-text label">
					{{ FormMSG(4, 'Update title') }}
				</div>
			</div>
		</b-dropdown-item>
		<b-dropdown-divider />
		<b-dropdown-item action @click="deleteTask(item.id)">
			<div class="sub-task-dropdown-item-action">
				<div class="icon">
					<i class="fa fa-trash remove" aria-hidden="true"></i>
				</div>
				<div class="sub-task-text label">
					{{ FormMSG(6, 'Remove') }}
				</div>
			</div>
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { delMainTask } from '@/cruds/task.crud';

export default {
	name: 'SubTaskContextMenu',
	mixins: [languageMessages],
	props: {
		item: {
			type: Object,
			required: true,
			default: null
		},
		index: {
			type: Number,
			required: true,
			default: 0
		}
	},
	methods: {
		async deleteTask(id) {
			await delMainTask(id);
			this.$emit('sub-task:removed', true);

			this.$toast({
				message: this.FormMSG(1, 'Sub task removed successfully'),
				title: this.FormMSG(2, 'Success'),
				variant: 'success'
			});
		},
		updateTitle(item) {
			// console.log('updateTitle: ', item)
			this.$emit('sub-task:update-title', item.id);
		}
	}
};
</script>
