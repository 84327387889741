<template>
	<div>
		<div v-if="materials.length === 0" ref="empty-image" class="text-center empty-item-text">
			{{ FormMSG(4, 'No material Found') }}
		</div>
		<div v-else>
			<card-list-builder v-if="$screen.width < 992" :items="materials" :fields="materialMobileFields">
				<template slot="actions" slot-scope="data">
					<b-button variant="primary" size="sm" @click="editMaterial(data.item)" class="mr-1 btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
					</b-button>
					<b-button variant="danger" size="sm" @click="deleteMaterial(data.item.id)" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
					</b-button>
				</template>
			</card-list-builder>
			<b-table
				v-else
				ref="materialTable"
				:hover="hover"
				:items="materials"
				:fields="materialFields"
				:current-page="currentPage"
				:per-page="perPage"
				:head-variant="hv"
				responsive="sm"
				bordered
				small
				:empty-text="FormMSG(1, 'No material found')"
				show-empty
			>
				<template v-slot:cell(edit)="data">
					<b-button variant="primary" size="sm" @click="editMaterial(data.item)" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
					</b-button>
				</template>
				<template v-slot:cell(delete)="data">
					<b-button variant="danger" size="sm" @click="deleteMaterial(data.item.id)" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
					</b-button>
				</template>
			</b-table>
		</div>
		<task-material-modal v-model="isOpenMaterialModal" :material="material" @task-material-modal:cancel="onCancelMaterialModal" />
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency } from '~helpers';
import { delTaskItem, getTaskItems } from '@/cruds/task.crud';
import TaskMaterialModal from '@/components/Modals/TaskMaterialModal';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'TaskMaterialList',
	mixins: [languageMessages, globalMixin],
	props: {
		taskId: {
			type: [String, Number],
			required: true,
			default: 0
		}
	},
	computed: {
		baseFields() {
			return [
				{
					key: 'id',
					label: '#ID',
					formatter: (v) => v,
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'name',
					label: this.FormMSG(2, 'Name'),
					sortable: true
				},
				{
					key: 'unit',
					label: this.FormMSG(3, 'Unit'),
					formatter: (v, z, root) => {
						if (root.unit > 0) {
							return this.units[root.unit].text;
						}

						return root.customUnit;
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'unitPrice',
					label: this.FormMSG(6, 'Price'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'quantity',
					label: this.FormMSG(5, 'Quantity'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'totalPrice',
					label: this.FormMSG(7, 'Total'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'edit',
					label: this.FormMSG(8, 'Edit'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v,
					width: '5%'
				},
				{
					key: 'delete',
					label: this.FormMSG(9, 'Remove'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v,
					width: '5%'
				}
			];
		},
		materialFields() {
			return this.baseFields;
		},
		materialMobileFields() {
			const keys = ['id', 'edit', 'delete'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		},
		units() {
			return this.FormMenu(1317);
		}
	},
	components: {
		TaskMaterialModal
	},
	data() {
		return {
			hover: true,
			hv: 'dark',
			perPage: 0,
			currentPage: 1,
			isOpenMaterialModal: false,
			material: {},
			materials: []
		};
	},
	async mounted() {
		this.materials = await getTaskItems(this.taskId);
	},
	methods: {
		editMaterial(item) {
			// console.log('Open material modal');
			// this.$emit('task-material:materialUpdated', item);
			this.material = item;
			this.isOpenMaterialModal = true;
		},
		async deleteMaterial(id) {
			let action = async () => {
				await delTaskItem(id).then((res) => {
					if (res) {
						this.materials.splice(
							this.materials.findIndex((material) => material.id === id),
							1
						);
						this.$emit('task-material:materialRemoved', id);
						this.$toast({
							message: this.FormMSG(10, 'Material removed successfully'),
							title: this.FormMSG(11, 'Success'),
							variant: 'success'
						});
					} else {
						this.$toast({
							message: this.FormMSG(12, 'An error was occured when deleting a material.'),
							title: this.FormMSG(13, 'Error'),
							variant: 'danger'
						});
					}
				});
			};
			this.confirmModal(action, this.FormMSG(14, 'Are you sure ?'));
		},
		async onCancelMaterialModal(payload) {
			this.isOpenMaterialModal = payload;
			this.$emit('task-material:reloadData', this.taskId);
		},
		async reloadData(taskId) {
			this.materials = await getTaskItems(taskId);
		}
	}
};
</script>
