<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<b-card>
				<b-card-header>
					<b-row>
						<b-col md="4" lg="4">
							<h4>{{ rendTitle }}</h4>
						</b-col>
						<b-col md="8" lg="8">
							<b-row :class="`${$screen.width >= 992 ? 'pull-right pr-2' : 'pull-right'}`">
								<b-col cols="12">
									<router-link :to="`/${pathPlanning}`" tag="b-button" class="btn btn-warning mr-2">
										<i class="fa fa-calendar" aria-hidden="true" />
										&nbsp;{{ FormMSG(256, 'Plannings') }}
									</router-link>
									<b-button @click="backToList" variant="success" size="md" class="btn btn-primary btn-md">
										{{ this.FormMSG(3, 'Back to list') }}&nbsp;<i class="icon-action-undo"></i>
									</b-button>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-card-header>
				<b-card-body>
					<b-tabs
						cards
						active-nav-item-class="text-uppercase font-weight-bold"
						active-tab-class=""
						title-item-class="text-uppercase"
						content-class="p-3"
					>
						<!-- begin tab general -->
						<b-tab :title="FormMSG(67, 'Generals')">
							<b-row v-if="!isMyWorkSpace">
								<b-col cols="12">
									<b-card no-body header-tag="header" footer-tag="footer">
										<template #header>
											<div class="d-flex flex-row">
												<div class="mr-2 text-success task-icon-card-header">
													<i class="fa fa-share-alt" aria-hidden="true"></i>
												</div>
												<div class="text-muted task-label-card-header">
													<h6 class="mb-0 font-weight-bold">{{ FormMSG(47, 'Tree view tasks') }}</h6>
												</div>
											</div>
										</template>
										<b-card-body>
											<task-tree-view :items="treeViewTasks" @task-tree-view:select="taskSelected" />
										</b-card-body>
									</b-card>
								</b-col>
							</b-row>
							<fieldset class="scheduler-border">
								<legend class="scheduler-border">{{ FormMSG(70, 'Form') }}</legend>
								<b-form @submit.prevent="onSubmit">
									<b-row>
										<b-col
											:md="`${!isMyWorkSpace ? ($screen.width <= 992 ? 12 : 10) : 12}`"
											:lg="`${!isMyWorkSpace ? ($screen.width <= 992 ? 12 : 10) : 12}`"
										>
											<b-form-group
												:label="FormMSG(77, 'N° order')"
												label-for="order-number"
												:label-cols="3"
												:horizontal="true"
												v-if="!isMyWorkSpace"
											>
												<b-form-input
													type="text"
													v-model="task.orderNumber"
													:placeholder="FormMSG(78, 'Order Number...')"
													disabled
												></b-form-input>
											</b-form-group>
											<b-form-group :label="FormMSG(16, 'Name')" label-for="name" :label-cols="3" :horizontal="true">
												<b-form-input
													type="text"
													v-model="task.name"
													:placeholder="FormMSG(4, 'Task name...')"
													:disabled="isMyWorkSpace"
												></b-form-input>
											</b-form-group>
											<b-form-group :label="FormMSG(17, 'Description')" label-for="Description" :label-cols="3" :horizontal="true">
												<b-form-textarea
													type="text"
													v-model="task.description"
													:placeholder="FormMSG(6, 'Task description...')"
													rows="16"
													:disabled="isMyWorkSpace"
												/>
											</b-form-group>
											<b-form-group
												:label="FormMSG(71, 'To Be Planned ?')"
												label-for="ToPlan"
												:label-cols="3"
												:horizontal="true"
												v-if="!isMyWorkSpace"
											>
												<b-form-checkbox size="lg" v-model="task.putInPlanning" stacked> </b-form-checkbox>
											</b-form-group>
											<b-form-group :label="FormMSG(21, 'Status')" label-for="Status" :label-cols="3" :horizontal="true">
												<b-form-select :options="statuses" v-model="task.status" :disabled="isMyWorkSpace"></b-form-select>
											</b-form-group>
											<b-form-group :label="FormMSG(22, 'Priority')" label-for="Priority" :label-cols="3" :horizontal="true">
												<b-form-select :options="priorities" v-model="task.priority" :disabled="isMyWorkSpace"></b-form-select>
											</b-form-group>
											<b-form-group :label="FormMSG(18, 'Customer')" label-for="Customer" :label-cols="3" :horizontal="true">
												<customer-selector
													:client-id="task.clientId"
													:location-id="task.locationId"
													@client:selected="selectClient"
													@client-location:selected="selectLocation"
													@client-location:removed="onLocationRemoved"
													:add-label="FormMSG(37, 'Add')"
													:update-label="FormMSG(76, 'Update')"
													:placeholder="FormMSG(32, 'Choose customer ...')"
													:location-placeholder="FormMSG(75, 'Choose location ...')"
													:disabled="isMyWorkSpace"
													:can-do-add="!isMyWorkSpace"
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(19, 'Type')" label-for="Type" :label-cols="3" :horizontal="true">
												<task-type-selector
													@task-type:selected="selectType"
													@task-type:clearSelection="clearType"
													:add-label="FormMSG(38, 'Add')"
													:placeholder="FormMSG(34, 'Choose type ...')"
													:task-type-id="task.taskTypeId"
													:disabled="isMyWorkSpace"
													:can-do-add="!isMyWorkSpace"
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(20, 'Duration')" label-for="Duration" :label-cols="3" :horizontal="true">
												<div class="d-flex row">
													<div class="col-6">
														<div class="d-flex row">
															<div class="col-12">
																<div class="d-flex flex-row">
																	<b-form-input
																		type="time"
																		:value="task.duration && task.duration.split('T')[1].substring(0, 5)"
																		@update="setDuration"
																		:disabled="isMyWorkSpace"
																	/>
																	<div class="ml-2 task-label text-success font-weight-bold">
																		{{ FormMSG(51, 'Hours/d') }}
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="col-6">
														<div class="d-flex row">
															<div class="col-12">
																<div class="d-flex flex-row">
																	<b-form-input
																		v-model="task.dayDuration"
																		type="number"
																		step="1"
																		min="0"
																		placeholder="1"
																		:disabled="isMyWorkSpace"
																	></b-form-input>
																	<div class="ml-2 task-label text-success font-weight-bold">
																		{{ FormMSG(50, 'Days') }}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</b-form-group>
											<b-row>
												<b-col md="6" lg="6">
													<fieldset class="scheduler-border">
														<legend class="scheduler-border">{{ FormMSG(59, 'Desired') }}</legend>
														<b-form-group
															class="bootstrap-date-selection"
															:label="FormMSG(58, 'Start Date')"
															label-for="DesiredDate"
															:label-cols="2"
															:horizontal="true"
														>
															<b-row>
																<b-col
																	:md="`${$screen.width < 992 ? 12 : 6}`"
																	:lg="`${$screen.width < 992 ? 12 : 6}`"
																	:class="`${$screen.width < 992 ? 'mb-1' : ''}`"
																>
																	<b-form-datepicker
																		v-model="task.desiredStart"
																		:label-no-date-selected="FormMSG(31, 'No date selected')"
																		:locale="currentLang"
																		:disabled="isMyWorkSpace"
																	/>
																</b-col>
																<b-col :md="`${$screen.width < 992 ? 12 : 6}`" :lg="`${$screen.width < 992 ? 12 : 6}`">
																	<b-form-timepicker
																		v-model="desiredStartHour"
																		type="text"
																		required
																		:placeholder="FormMSG(72, 'No time selected')"
																		:locale="currentLang"
																		:disabled="isMyWorkSpace"
																	/>
																</b-col>
															</b-row>
														</b-form-group>
													</fieldset>
												</b-col>
												<b-col md="6" lg="6">
													<fieldset class="scheduler-border">
														<legend class="scheduler-border">{{ FormMSG(60, 'Real') }}</legend>
														<b-form-group
															class="bootstrap-date-selection"
															:label="FormMSG(61, 'Start Date')"
															label-for="StartDate"
															:label-cols="2"
															:horizontal="true"
														>
															<b-row>
																<b-col
																	:md="`${$screen.width < 992 ? 12 : 6}`"
																	:lg="`${$screen.width < 992 ? 12 : 6}`"
																	:class="`${$screen.width < 992 ? 'mb-1' : ''}`"
																>
																	<b-form-datepicker
																		v-model="task.start"
																		:label-no-date-selected="FormMSG(62, 'No date selected')"
																		:locale="currentLang"
																		disabled
																	/>
																</b-col>
																<b-col :md="`${$screen.width < 992 ? 12 : 6}`" :lg="`${$screen.width < 992 ? 12 : 6}`">
																	<b-form-timepicker
																		v-model="startHour"
																		type="text"
																		required
																		:placeholder="FormMSG(72, 'No time selected')"
																		:locale="currentLang"
																		disabled
																	/>
																</b-col>
															</b-row>
														</b-form-group>
														<b-form-group
															class="bootstrap-date-selection"
															:label="FormMSG(63, 'End Date')"
															label-for="EndDate"
															:label-cols="2"
															:horizontal="true"
														>
															<b-row>
																<b-col
																	:md="`${$screen.width < 992 ? 12 : 6}`"
																	:lg="`${$screen.width < 992 ? 12 : 6}`"
																	:class="`${$screen.width < 992 ? 'mb-1' : ''}`"
																>
																	<b-form-datepicker
																		v-model="task.end"
																		:label-no-date-selected="FormMSG(64, 'No date selected')"
																		:locale="currentLang"
																		disabled
																	/>
																</b-col>
																<b-col :md="`${$screen.width < 992 ? 12 : 6}`" :lg="`${$screen.width < 992 ? 12 : 6}`">
																	<b-form-timepicker
																		v-model="endHour"
																		type="text"
																		required
																		:placeholder="FormMSG(72, 'No time selected')"
																		:locale="currentLang"
																		disabled
																	/>
																</b-col>
															</b-row>
														</b-form-group>
													</fieldset>
												</b-col>
											</b-row>
										</b-col>
										<b-col :md="`${$screen.width <= 992 ? 12 : 2}`" :lg="`${$screen.width <= 992 ? 12 : 2}`" v-if="!isMyWorkSpace">
											<b-form-group>
												<b-button type="submit" variant="primary" size="md" block :disabled="isValid">
													<span>{{ FormMSG(12, 'Save') }}</span>
												</b-button>
											</b-form-group>
											<b-form-group>
												<b-button
													type="button"
													variant="danger"
													size="md"
													block
													:disabled="isValid"
													@click="deleteTask(task.taskPlanningId, task.id)"
												>
													<span>{{ FormMSG(79, 'Delete') }}</span>
												</b-button>
											</b-form-group>
										</b-col>
									</b-row>
								</b-form>
							</fieldset>
							<b-row>
								<b-col cols="12">
									<b-row v-if="!isMyWorkSpace">
										<b-col cols="12">
											<b-card no-body header-tag="header" footer-tag="footer">
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-tasks" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(42, 'Sub tasks') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<sub-task
														:items="subTasks"
														@sub-task:removed="removeSubTask"
														@sub-task:titleUpdated="updateSubTask"
														@sub-task:updateStatus="updateStatus"
														@sub-task:updatePriority="updatePriority"
													/><!-- :parent-task-id="id" -->
												</b-card-body>
												<template #footer>
													<b-row>
														<b-col md="9" lg="9">
															<b-form-input
																type="text"
																v-model.trim="$v.subTask.name.$model"
																:placeholder="FormMSG(43, 'Title sub task...')"
																:class="{ 'is-invalid': subTaskSubmitted && $v.subTask.name.$error }"
																style="height: 35px"
															></b-form-input>
															<div v-if="subTaskSubmitted && !$v.subTask.name.required" class="invalid-feedback">
																{{ FormMSG(45, 'Sub task title is required') }}
															</div>
														</b-col>
														<b-col md="3" lg="3" :class="`${$screen.width < 992 ? 'mt-2' : ''}`">
															<b-form-group class="my-0">
																<b-button
																	type="button"
																	variant="outline-primary"
																	size="md"
																	class="py-2 tags-assigned"
																	block
																	@click="addSubTask"
																>
																	<span>{{ FormMSG(44, 'Add') }}</span>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</template>
											</b-card>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12">
											<b-card no-body header-tag="header" footer-tag="footer">
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-user" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(28, 'Assigned users') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<task-assigned-user :items="users" :col="`${$screen.width < 992 ? 12 : 4}`" />
												</b-card-body>
												<template #footer v-if="!isMyWorkSpace && canManagePlanning">
													<b-row>
														<b-col cols="12">
															<task-user-selector
																:assigned-to="task.assignedTo"
																@task-user-selector:userAdded="assignedNewUser"
																@task-user-selector:userDeleted="removeUserAssignee"
															/>
														</b-col>
													</b-row>
												</template>
											</b-card>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12">
											<b-card no-body header-tag="header" footer-tag="footer">
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-users" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(30, 'Assigned teams') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<task-assigned-team
														:items="teams"
														:col="`${$screen.width < 992 ? 12 : 4}`"
														:can-do-edit="!isMyWorkSpace && canManagePlanning"
													/>
												</b-card-body>
												<template #footer v-if="!isMyWorkSpace && canManagePlanning">
													<b-row>
														<b-col md="9" lg="9">
															<task-team-selector
																:assigned-team="task.assignedTeam"
																@task-team-selector:teamAdded="assignedNewTeam"
																@task-team-selector:teamDeleted="removeTeamAssignee"
															/>
														</b-col>
														<b-col md="3" lg="3" :class="`${$screen.width < 992 ? 'mt-2' : ''}`">
															<b-form-group class="my-0">
																<b-button
																	type="button"
																	variant="outline-primary"
																	size="md"
																	class="py-2 px-4 tags-assigned"
																	@click="openAssignationTeamModal"
																	block
																>
																	<span>{{ FormMSG(29, 'Add') }}</span>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</template>
											</b-card>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-tab>
						<!-- end tab general -->
						<!-- begin tab documents -->
						<b-tab :title="FormMSG(68, 'Documents')">
							<b-row>
								<b-col cols="12">
									<b-row>
										<b-col cols="12">
											<b-card no-body header-tag="header" footer-tag="footer">
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-files-o" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(25, 'Documents') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<b-row>
														<b-col cols="12">
															<green-film-docs
																ref="cardDocumentList"
																:GFId="id"
																:show-input-file="false"
																GFType="task"
																GFSubType="documents"
																@el:updated="greenFilmDocsMounted"
															/>
															<div v-if="elDocMounted === true && greenFilmDocLength === 0" class="text-center empty-item-text">
																{{ FormMSG(74, 'No document selected') }}
															</div>
														</b-col>
													</b-row>
												</b-card-body>
												<template #footer>
													<b-row>
														<b-col md="9" lg="9"></b-col>
														<b-col md="3" lg="3">
															<b-form-group class="my-0">
																<b-button
																	type="button"
																	variant="outline-primary"
																	size="md"
																	class="py-2 px-4 tags-assigned"
																	@click="openDocumentModal"
																	block
																>
																	<span>{{ FormMSG(14, 'Add') }}</span>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</template>
											</b-card>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12">
											<b-card no-body>
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-image" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(24, 'Images') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<div v-if="task.images.length === 0" ref="empty-image" class="text-center empty-item-text">
														{{ FormMSG(52, 'No image selected') }}
													</div>
													<ImgShowroomTable v-else :img-data="images" @image:removed="imageRemoved" />
												</b-card-body>
												<template #footer>
													<b-row>
														<b-col md="9" lg="9"></b-col>
														<b-col md="3" lg="3">
															<b-form-group class="my-0">
																<b-button
																	type="button"
																	variant="outline-primary"
																	size="md"
																	class="py-2 px-4 tags-assigned"
																	@click="togglePictureModal"
																	block
																>
																	<span>{{ FormMSG(13, 'Add') }}</span>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</template>
											</b-card>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-tab>
						<!-- end tab documents -->
						<!-- begin tab materials -->
						<b-tab :title="FormMSG(69, 'Materials')">
							<b-row>
								<b-col cols="12">
									<b-row>
										<b-col cols="12">
											<b-card no-body>
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-shopping-cart" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(53, 'Materials') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<task-material-list ref="task-material-list" :task-id="id" @task-material:reloadData="reloadMaterialList" />
												</b-card-body>
												<template #footer>
													<b-row>
														<b-col md="9" lg="9"></b-col>
														<b-col md="3" lg="3">
															<b-form-group class="my-0">
																<b-button
																	type="button"
																	variant="outline-primary"
																	size="md"
																	class="py-2 px-4 tags-assigned"
																	@click="addNewMaterial"
																	block
																>
																	<span>{{ FormMSG(56, 'Add') }}</span>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</template>
											</b-card>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-tab>
						<!-- end tab materials -->
						<!-- begin tab comments -->
						<b-tab :title="FormMSG(73, 'Comments')" @click="tabComment">
							<b-row>
								<b-col cols="12">
									<b-row>
										<b-col cols="12">
											<b-card no-body header-tag="header" footer-tag="footer">
												<template #header>
													<div class="d-flex flex-row">
														<div class="mr-2 text-success task-icon-card-header">
															<i class="fa fa-exchange" aria-hidden="true"></i>
														</div>
														<div class="text-muted task-label-card-header">
															<h6 class="mb-0 font-weight-bold">{{ FormMSG(26, 'Comments') }}</h6>
														</div>
													</div>
												</template>
												<b-card-body>
													<task-comment
														:key-value="{
															xid,
															imageParentId: parseInt(task.rootId, 10) === 0 ? parseInt(task.id, 10) : parseInt(task.rootId, 10)
														}"
														:new-comment="newComment"
													/>
												</b-card-body>
												<template #footer>
													<task-editor
														:task-xid="xid"
														:reply-to="0"
														:image-parent-id="parseInt(task.rootId, 10) === 0 ? parseInt(task.id, 10) : parseInt(task.rootId, 10)"
														@task-comment:added="commentAdded"
													/>
												</template>
											</b-card>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-tab>
						<!-- end tab comments -->
						<task-document-modal v-model="isOpenDocumentModal" :task-id="id" @task-document:hidden="onHideDocumentModal" />
						<task-material-modal
							v-model="isOpenMaterialModal"
							:material="material"
							@task-material-modal:cancel="onCancelMaterialModal"
							@task-material-modal:taskMaterialUpdated="onTaskMaterialUpdated"
						/>
						<capture
							v-model="isCaptureModalOpen"
							multiple-img
							dynamic-crop-size
							:edit-data="task"
							:parent-id="id"
							parent-type="task"
							parent-sub-type="images"
							@change="handleImgsTaked"
						/>
					</b-tabs>
				</b-card-body>
			</b-card>
		</transition>
	</errorContainer>
</template>

<script>
import { updatedTask, getTask, getTasks, updatedTaskItem, getMainSubTasks } from '@/cruds/task.crud';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import languageMessages from '@/mixins/languageMessages';
import meterHelperMixin from '../../components/LocationService/meterHelperMixin';
import CustomerSelector from '@/components/customer/CustomerSelector';
import TaskTypeSelector from '@/components/TaskType/TaskTypeSelector';
import TaskDocumentModal from '@/components/Modals/TaskDocumentModal';
import GreenFilmDocs from '@/components/greenfilm/greenfilmdocs';
import { store } from '@/store';
import Capture from '@/components/ExpenseService/Capture';
import ImgShowroomTable from '@/components/Task/ImgShowroomTable';
import TaskAssignedUser from '@/components/Task/TaskAssignedUser';
import TaskAssignedTeam from '@/components/Task/TaskAssignedTeam';
import TaskComment from '@/components/Task/TaskComment';
import SubTask from '@/components/Task/SubTask';
import TaskTreeView from '@/components/Task/TaskTreeView';
import moment from 'moment';
import * as _ from 'lodash';
import { isNil } from '~utils';
import TaskMaterialList from '@/components/Task/Material/List';
import TaskEditor from '@/components/Task/TaskEditor';
import TaskUserSelector from '@/components/Task/TaskUserSelector';
import TaskTeamSelector from '@/components/Task/TaskTeamSelector';
import TaskMaterialModal from '@/components/Modals/TaskMaterialModal';
import { delTask } from '@/cruds/task.crud';
import GlobalMixin from '@/mixins/global.mixin';
// import Comments from "@/components/Plannings/Comments";

export function validDuration(value) {
	const tmpValue = value.split('T')[1].substring(0, 5);
	if (tmpValue === '00:00') return false;

	return value;
}

export default {
	name: 'TaskDetails',
	mixins: [languageMessages, GlobalMixin, validationMixin, meterHelperMixin],
	data() {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			id: 0,
			taskPlanningId: 0,
			readonly: true,
			task: {
				id: 0,
				name: '',
				description: '',
				parentTaskId: 0,
				taskTypeId: 0,
				clientId: 0,
				duration: '0001-01-01T00:00:00Z',
				status: 0,
				priority: 0,
				// dueDate: null,
				images: [],
				dayDuration: 1,
				desiredStart: null,
				start: null,
				end: null,
				putInPlanning: true,
				taskPlanningId: 0,
				locationId: 0
			},
			subTask: {
				id: 0,
				name: '',
				description: '',
				parentTaskId: 0,
				taskTypeId: 0,
				clientId: 0,
				duration: '0001-01-01T00:00:00Z',
				status: 0,
				priority: 0,
				// dueDate: null,
				images: [],
				dayDuration: 1,
				desiredStart: null,
				start: null,
				end: null,
				putInPlanning: true,
				taskPlanningId: 0,
				locationId: 0
			},
			// submitted: false,
			isValidCustomer: true,
			// isValidType: true,
			isValid: false,
			isOpenDocumentModal: false,
			users: [],
			teams: [],
			action: '',
			isCaptureModalOpen: false,
			team: {
				name: 'Default team',
				description: 'Default description team'
			},
			teamId: 0,
			rawUsers: [],
			subTaskSubmitted: false,
			subTasks: [],
			treeViewTasks: [],
			taskRoot: null,
			taskType: null,
			isTaskTypeModified: false,
			elDocMounted: false,
			greenFilmDocLength: 0,
			assignedTo: [],
			xid: '',
			newComment: null,
			isMyWorkSpace: false,
			pathTask: '',
			pathPlanning: '',
			isOpenMaterialModal: false,
			material: {},
			oldDesiredStart: ''
		};
	},
	validations: {
		subTask: {
			name: {
				required,
				min: minLength(5)
			},
			description: {
				min: minLength(10)
			}
		}
	},
	components: {
		CustomerSelector,
		TaskTypeSelector,
		TaskDocumentModal,
		GreenFilmDocs,
		Capture,
		ImgShowroomTable,
		TaskAssignedUser,
		TaskAssignedTeam,
		TaskComment,
		SubTask,
		TaskTreeView,
		TaskMaterialList,
		TaskEditor,
		TaskUserSelector,
		TaskTeamSelector,
		TaskMaterialModal
		// Comments,
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendTitle() {
			// return this.id === 0 ? this.FormMSG(1, "New Task") : this.FormMSG(2, "Update Task");
			return this.FormMSG(2, 'Update Task Planning');
		},
		statuses() {
			return this.FormMenu(1122);
		},
		priorities() {
			return this.FormMenu(1123);
		},
		images() {
			const tmp = [];
			this.task.images.forEach((img) => {
				tmp.push({
					filename: img
				});
			});

			return tmp;
		},
		desiredStartHour: {
			get: function () {
				if (!isNil(this.task.desiredStart) && this.task.desiredStart !== '') {
					return this.task.desiredStart.match(/(\d{2}:\d{2}:\d{2})/g)[0];
				}
			},
			set: function (value) {
				this.task.desiredStart =
					this.task.desiredStart.length === 10
						? `${this.task.desiredStart}T${value}Z`
						: this.task.desiredStart.replace(/(\d{2}:\d{2}:\d{2})/g, value);

				return value;
			}
		},
		startHour() {
			if (!isNil(this.task.start) && this.task.start !== '') {
				return this.task.start.match(/(\d{2}:\d{2}:\d{2})/g)[0];
			}
		},
		endHour() {
			if (!isNil(this.task.end) && this.task.end !== '') {
				return this.task.end.match(/(\d{2}:\d{2}:\d{2})/g)[0];
			}
		},
		canManagePlanning() {
			return store.canAssign();
		}
	},
	created() {
		const { id } = this.$route.params;

		if (!isNaN(parseInt(id, 10))) {
			this.taskPlanningId = parseInt(id, 10);
			this.action = 'update';
		} else {
			this.taskPlanningId = 0;
			this.action = 'add';
		}
	},
	mounted() {
		this.$nextTick(async () => {
			const currentPath = this.$router.currentRoute.path;
			this.isMyWorkSpace = new RegExp('(mytasks)', 'g').test(currentPath);
			this.pathTask = this.isMyWorkSpace ? 'mytasks' : 'tasks';
			this.pathPlanning = this.isMyWorkSpace ? 'myplannings' : 'plannings';
			store.state.taskAction = this.action;
			await getTask(this.taskPlanningId).then(async (record) => {
				this.task = store.state.task = record;
				this.id = this.task.id;
				// console.log("this.tasksk: ", this.task)
				this.taskRoot = store.state.taskRoot = this.task.rootId === 0 ? this.id : this.task.rootId;
				// console.log("this.taskRoot: ", this.taskRoot)

				if (this.task.desiredStart === '0001-01-01T00:00:00Z') {
					this.task.desiredStart = '';
				} else {
					this.task.desiredStart = new moment(this.task.desiredStart).locale(`${this.currentLang}`).format('YYYY-MM-DDTHH:mm:ssZ');
				}
				this.oldDesiredStart = this.task.desiredStart;

				if (this.task.start === '0001-01-01T00:00:00Z') {
					this.task.start = '';
				} else {
					this.task.start = new moment(this.task.start).locale(`${this.currentLang}`).format('YYYY-MM-DDTHH:mm:ssZ');
				}

				if (this.task.end === '0001-01-01T00:00:00Z') {
					this.task.end = '';
				} else {
					this.task.end = new moment(this.task.end).locale(`${this.currentLang}`).format('YYYY-MM-DDTHH:mm:ssZ');
				}
				// get all resource users
				this.getTaskResourceUsers(this.task);
				// get all resource teams
				this.getTaskResourceTeams(this.task);
				// reload all services
				await this.reloadAllServices(this.id);
			});
		});
	},
	methods: {
		async handleImgsTaked(imgs) {
			for (let i = 0; i < imgs.length; i++) {
				const imgSplitted = imgs[i].split('.')[0];
				this.task.images.push(imgSplitted);
			}
		},
		async deleteTask(taskPlanningId, id) {
			const action = async () => {
				await delTask(taskPlanningId, id).then((r) => {
					if (r) {
						this.$toast({
							message: this.FormMSG(81, 'Task removed successfully'),
							title: this.FormMSG(82, 'Success'),
							variant: 'success'
						});
						this.$router.push({ path: '/tasks' });
					} else {
						this.$toast({
							message: this.FormMSG(83, 'An error was occured when deleting a task.'),
							title: this.FormMSG(84, 'error'),
							variant: 'danger'
						});
					}
				});
			};
			this.confirmModal(action, this.FormMSG(80, 'Are you sure ?'));
		},
		togglePictureModal() {
			this.isCaptureModalOpen = true;
		},
		setDuration(payload) {
			if (payload === '' || payload === '00:00') {
				this.task.duration = '0001-01-01T00:00:00Z';
			} else {
				this.task.duration = `0001-01-01T${payload}:00Z`;
			}
		},
		async onSubmit() {
			let updateTask = {};
			if (this.action === 'update') {
				updateTask.name = this.task.name;
				updateTask.description = this.task.description;
				updateTask.taskTypeId = parseInt(this.task.taskTypeId, 10);
				updateTask.clientId = parseInt(this.task.clientId, 10);
				updateTask.duration = this.task.duration;
				updateTask.dayDuration = parseInt(this.task.dayDuration, 10);
				updateTask.status = this.task.status;
				updateTask.priority = this.task.priority;
				// updateTask.dueDate = this.task.dueDate;
				// updateTask.images = this.task.images;
				if (this.task.desiredStart.length === 10) {
					// console.log('desiredStart: ', this.task.desiredStart);
					this.task.desiredStart = this.task.desiredStart + this.oldDesiredStart.substring(10);
				}
				updateTask.desiredStart = this.task.desiredStart;
				updateTask.putInPlanning = this.task.putInPlanning;
				updateTask.taskPlanningId = this.task.taskPlanningId;
				updateTask.locationId = parseInt(this.task.locationId, 10);
				// this.task = updateTask;
			}
			// TODO use promise updatedTask
			await updatedTask(this.task.id, updateTask).then(async (res) => {
				if (res) {
					if (this.isTaskTypeModified) {
						await this.reloadMaterialList(this.id);
					}

					this.$toast({
						message: this.FormMSG(36, 'Task updated successfully'),
						title: this.FormMSG(54, 'Success'),
						variant: 'success'
					});

					// set tree view
					this.setTreeView();
				}
			});
		},
		selectClient(payload) {
			// console.log({payload})
			if (payload !== null) {
				const { id } = payload;
				this.task.clientId = id;
				this.isValidCustomer = true;
			} else {
				this.task.clientId = 0;
				this.isValidCustomer = false;
			}
		},
		selectLocation(payload) {
			if (payload !== null) {
				const { id } = payload;
				this.task.locationId = parseInt(id, 10);
			} else {
				this.task.locationId = 0;
			}
		},
		selectType(payload, arg) {
			if (payload !== null) {
				const { id, duration, dayDuration, priority, description } = payload;
				this.task.taskTypeId = parseInt(id, 10);
				this.task.duration = duration;
				this.task.dayDuration = dayDuration;
				this.task.priority = priority;
				// this.isValidType = true;
				this.task.description = description;
			} else {
				this.task.taskTypeId = 0;
				this.task.duration = '0001-01-01T01:00:00Z';
				this.task.dayDuration = 1;
				this.task.priority = 0;
				// this.isValidType = false;
				this.task.description = '';
			}
			this.isTaskTypeModified = arg;
		},
		clearType(payload) {
			if (payload) {
				this.task.taskTypeId = 0;
				this.task.duration = '0001-01-01T01:00:00Z';
				this.task.dayDuration = 1;
				this.isTaskTypeModified = true;
				this.task.description = '';
			}
		},
		openDocumentModal() {
			this.isOpenDocumentModal = true;
		},
		onHideDocumentModal(payload) {
			this.isOpenDocumentModal = false;
			// do reload table documents in card
			this.reloadDocumentList(payload);
		},
		openAssignationTeamModal() {
			this.$router.push({
				path: '/teams'
			});
		},
		getTaskResourceUsers(task) {
			// get all users assignees
			this.users = task.assignedTo;
		},
		getTaskResourceTeams(task) {
			// get all teams assignees
			this.teams = task.assignedTeam;
		},
		async addSubTask() {
			this.subTaskSubmitted = true;
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			let task = Object.assign({}, this.subTask);
			const today = new Date();
			let tomorrow = new Date(today);
			tomorrow.setDate(tomorrow.getDate() + 1);
			tomorrow.setHours(0, 0, 0);
			task.desiredStart = tomorrow;
			task.clientId = parseInt(this.task.clientId, 10);
			task.parentTaskId = parseInt(this.id, 10);
			task.taskPlanningId = parseInt(this.task.taskPlanningId, 10);
			// console.log('task: ', task);
			await updatedTask(0, task).then(async (id) => {
				if (id) {
					// console.log('new id sub task: ', id);
					// reset field
					this.subTask.name = '';
					this.subTaskSubmitted = false;

					// set tree view
					this.setTreeView();

					// reload
					await this.getSubTasks(this.id);

					this.$toast({
						message: this.FormMSG(46, 'Sub Task added successfully'),
						title: this.FormMSG(54, 'Success'),
						variant: 'success'
					});
				}
			});
		},
		async getSubTasks(parentTaskId) {
			let today = new Date();
			today.setHours(0, 0, 0);

			this.subTasks = await getTasks(parentTaskId, {
				// startDate: today,
				ofToday: false,
				orderBy: true,
				loadItems: false,
				loadSubTasks: false,
				loadTeams: false,
				loadUsers: false,
				loadTreeView: false
			});
		},
		async removeSubTask(payload) {
			if (payload) {
				await this.reloadAllServices(this.id);
			}
		},
		backToList() {
			if (store.state.isAdministrationTasks) {
				this.$router.replace('/administration/tasks');
			} else {
				this.$router.push(`/${this.pathTask}`);
			}
		},
		async getTreeView(rootId, treeView) {
			try {
				if (isNil(rootId)) return;
				let initIndex = 0;
				let { tree, indexes } = treeView;
				if (tree.length === 0) {
					if (rootId === this.task.id) {
						tree.push({
							id: parseInt(this.task.id, 10),
							taskPlanningId: parseInt(this.task.taskPlanningId, 10),
							name: this.task.name
						});
					} else {
						const task = await getTask(null, rootId);
						tree.push({
							id: parseInt(task.id, 10),
							taskPlanningId: parseInt(task.taskPlanningId, 10),
							name: task.name
						});
					}
					indexes.push(initIndex);
				}

				return await this.getDeepTree(rootId, { tree, indexes });
			} catch (e) {
				console.log(e);
			}
		},
		async getDeepTree(rootId, treeView) {
			let { tree, indexes } = treeView;
			let treeData = treeView;
			const data = await getMainSubTasks(rootId);
			if (data.length > 0) {
				let getDeep;
				for (const [index, lastIndex] of indexes.entries()) {
					if (index === 0) {
						getDeep = tree[lastIndex];
					} else {
						getDeep = getDeep.children[lastIndex];
					}
				}
				this.$set(getDeep, 'children', []);
				const sIndexes = indexes.join(',');
				for (const [index, node] of data.entries()) {
					indexes.push(index);
					// numbering
					let numbering = '';
					indexes.forEach((value, index) => {
						if (index > 0) {
							numbering += (value + 1).toString() + '.';
						}
					});
					getDeep.children.push({
						id: parseInt(node.id, 10),
						taskPlanningId: parseInt(node.taskPlanningId, 10),
						name: numbering + ' ' + node.name
					});

					//merge
					_.merge(tree, getDeep);

					treeData = { tree, indexes };
					treeData = await this.getDeepTree(node.id, treeData);

					indexes = [];
					sIndexes.split(',').forEach((value) => {
						indexes.push(parseInt(value, 10));
					});
				}
			}

			return treeData;
		},
		setTreeView() {
			// set tree view
			new Promise(async (resolve, reject) => {
				resolve(
					await this.getTreeView(this.taskRoot, {
						tree: [],
						indexes: []
					})
				);
				reject(false);
			}).then((data) => {
				if (data !== false) {
					setTimeout(() => {
						this.treeViewTasks = data.tree;
					}, 500);
				}
			});
		},
		async taskSelected(data) {
			// console.log('nodeSelected: ', data);
			const { id } = this.$route.params;
			if (data.taskPlanningId !== parseInt(id, 10)) {
				this.task = store.state.task = await getTask(null, data.id);
				this.taskRoot = store.state.taskRoot = this.task.rootId === 0 ? this.task.id : this.task.rootId;
				// console.log('taskSelected.taskRoot: ', this.taskRoot)
				this.$router.push('/tasks/' + data.taskPlanningId);
				this.id = parseInt(data.id, 10);
				this.taskPlanningId = parseInt(data.taskPlanningId, 10);
				this.task.desiredStart = new moment(this.task.desiredStart).locale(`${this.currentLang}`).format('YYYY-MM-DDTHH:mm:ssZ');
				// console.log('taskSelected: ', this.task)
				// update all components

				this.$nextTick(async () => {
					// get all resource users
					this.getTaskResourceUsers(this.task);
					// get all resource teams
					this.getTaskResourceTeams(this.task);
					// reload all services
					await Promise.all([
						// get all sub tasks
						this.getSubTasks(this.task.id),
						// get all materials
						this.reloadMaterialList(this.task.id),
						// get all documents
						this.reloadDocumentList(this.task.id)
					]);
				});
			}
		},
		updateSubTask(payload) {
			if (payload) {
				// set tree view
				this.setTreeView();
			}
		},
		async updateStatus(task, value) {
			await updatedTask(task.id, {
				status: value,
				taskPlanningId: task.taskPlanningId
			}).then(async (r) => {
				await this.getSubTasks(this.id);

				this.$toast({
					message: this.FormMSG(48, 'Status updated successfully'),
					title: this.FormMSG(54, 'Success'),
					variant: 'success'
				});
			});
		},
		async updatePriority(task, value) {
			await updatedTask(task.id, {
				priority: value,
				taskPlanningId: task.taskPlanningId
			}).then(async (r) => {
				await this.getSubTasks(this.id);

				this.$toast({
					message: this.FormMSG(49, 'Priority updated successfully'),
					title: this.FormMSG(54, 'Success'),
					variant: 'success'
				});
			});
		},
		async addNewMaterial() {
			await updatedTaskItem(0, {
				name: this.FormMSG(66, 'Default material'),
				taskId: parseInt(this.id, 10)
			}).then(async (record) => {
				this.material = record;
				this.$toast({
					message: this.FormMSG(57, 'Material added successfully'),
					title: this.FormMSG(54, 'Success'),
					variant: 'success'
				});

				await this.reloadMaterialList(this.id).then((records) => {
					this.isOpenMaterialModal = true;
				});
			});
		},
		async reloadMaterialList(taskId) {
			this.$refs['task-material-list'].reloadData(taskId);
		},
		async reloadDocumentList(taskId) {
			this.$refs.cardDocumentList.reloadData(taskId);
		},
		async reloadAllServices(taskId) {
			// set tree view
			this.setTreeView();
			// get all sub tasks
			await this.getSubTasks(taskId);
			// get all documents
			await this.reloadDocumentList(taskId);
			// get all materials
			await this.reloadMaterialList(taskId);
		},
		imageRemoved(payload) {
			this.task.images.splice(
				this.task.images.findIndex((image) => (image.lastIndexOf('.') === -1 ? image : image.substring(0, image.lastIndexOf('.')) === payload)),
				1
			);
		},
		greenFilmDocsMounted(payload) {
			this.elDocMounted = true;
			this.greenFilmDocLength = payload;
		},
		assignedNewUser(payload) {
			this.task.assignedTo.push(payload);
		},
		removeUserAssignee(payload) {
			this.task.assignedTo.splice(
				this.task.assignedTo.findIndex((team) => team.id === payload),
				1
			);
		},
		assignedNewTeam(payload) {
			this.task.assignedTeam.push(payload);
		},
		removeTeamAssignee(payload) {
			this.task.assignedTeam.splice(
				this.task.assignedTeam.findIndex((team) => team.id === payload),
				1
			);
		},
		commentAdded(payload) {
			this.newComment = payload;
		},
		toString(value) {
			return value.toString();
		},
		tabComment(event) {
			window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
		},
		onCancelMaterialModal(payload) {
			this.isOpenMaterialModal = payload;
			this.$emit('task-material:reloadData', this.id);
		},
		async onTaskMaterialUpdated(payload) {
			await this.reloadMaterialList(this.id);
		},
		async onLocationRemoved(payload) {
			if (this.task.locationId == payload) {
				await updatedTask(this.task.id, {
					locationId: 0,
					taskPlanningId: this.task.taskPlanningId
				}).then((res) => {
					this.task.locationId = 0;
				});
			}
		}
	},
	watch: {
		task: {
			handler: function (n) {
				if (!isNil(n)) {
					if (!isNil(n.xid) && n.xid.length > 0) {
						this.xid = n.rootId === 0 ? n.xid : n.rootXid;
					}
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss">
.task-label {
	line-height: 32px;
}
</style>
